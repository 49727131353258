import { render, staticRenderFns } from "./ExaminationQuestions.vue?vue&type=template&id=310b3833&scoped=true"
import script from "./ExaminationQuestions.vue?vue&type=script&lang=js"
export * from "./ExaminationQuestions.vue?vue&type=script&lang=js"
import style0 from "./ExaminationQuestions.vue?vue&type=style&index=0&id=310b3833&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310b3833",
  null
  
)

export default component.exports