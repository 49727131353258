<template>
  <div >
    <!-- 顶部工具条 -->
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item label="所有题型:">
          <el-select v-model="filters.questionType"  placeholder="所有题型">
            <el-option 
             v-for="item in TypeOption"
             :key="item.value"
             :label="item.label"
             :value="item.value"
             >
            </el-option>           
          </el-select>
        </el-form-item>
        <el-form-item label="所有难度:">
          <el-select v-model="filters.questionGrade" placeholder="所有难度">
            <el-option 
              v-for="item in GradeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="范围:" placeholder="范围">
          <el-select v-model="filters.questionRange" >
            <el-option 
              v-for="item in RangeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="题库:">
          <el-select v-model="filters.questionCode" placeholder="请选择题库">
            <el-option 
              v-for="item in questionCodeArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label='关键字:'>
          <el-input placeholder="搜索题目" 
            v-model="filters.questionSearch"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 表格 -->
    <el-table 
      v-loading="loading" 
      :data="tableData" 
      style="width:100%" 
      border 
      highlight-current-row 
      @current-change="handlerowChange"
      @selection-change="handleSelectChange"
      v-if='isAlive'
    >
      <!-- <el-table-column label="编号" width="200" align="center" prop="QuestionsCode"></el-table-column> -->
      <el-table-column  label="题型" width="120" align="center" prop="QuestionsType">
        <template slot-scope="scope">
          <el-tag :type="''" v-if="scope.row.QuestionsType == 0">单选题</el-tag>
          <el-tag :type="'success'" v-if="scope.row.QuestionsType == 1">多选题</el-tag>
          <el-tag :type="'warning'" v-if="scope.row.QuestionsType == 2">判断题</el-tag>
        </template>
      </el-table-column>
      <el-table-column  label="题目" width="" align="center" prop="QuestionsTitle"></el-table-column>
      <!-- <el-table-column  label="试题难度" width="100" align="center" prop="Difficulty">
        <template slot-scope="scope">
          <el-tag :type="'warning'" v-if="scope.row.Difficulty == 0">高</el-tag>
          <el-tag :type="'success'" v-if="scope.row.Difficulty == 1">中</el-tag>
          <el-tag :type="''" v-if="scope.row.Difficulty == 2">低</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column  label="创建时间" width="150" align="center" prop="CreateTime" :formatter="formatCreateTime"></el-table-column>
      <!-- <el-table-column  label="范围" width="150" align="center" prop="UseRange">
        <template slot-scope="scope">
          <el-tag :type="'success'" v-if="scope.row.UseRange == 0">字典试题</el-tag>
          <el-tag :type="'warning'" v-if="scope.row.UseRange == 1">公开试题</el-tag>
          <el-tag :type="''" v-if="scope.row.UseRange == 2">私有试题</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="使用状态" width="100" align="center" prop="IsEnable">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.IsEnable == true ? 'success' : 'danger'" disable-transitions>{{scope.row.IsEnable == true ? "启用":"禁用"}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="题库" width="200" align="center" prop="QuestionCode">
        <template slot-scope="scope">
          <span v-if="scope.row.QuestionCode == 1">A库</span>
          <span v-else-if="scope.row.QuestionCode == 2">B库</span>
          <span v-else-if="scope.row.QuestionCode == 3">C库</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-col :span="24" class="pageBarContainer">
      <el-pagination
        small
        :page-sizes="pages.pageArr"
        :page-size="pages.pageSize"
        :current-page="pages.pageIndex"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount"
        @size-change="handleSizeChange" 
        @current-change="handleCurrentChange"
        class="pageBarContainer"
        >
      </el-pagination>
    </el-col>    
    <!-- 新增 -->
    <el-dialog
      title="新增题目"
      :visible.sync="addQuestionVisible"
      :close-on-click-modal="false"
      width="50%"
      >
      <el-form :model="addForm" label-width="110px" label-position="center" ref="addFormRef" :rules="addFormRules">
          <!-- <el-form-item label="编号:" prop="questionsCode">
            <el-input v-model="addForm.questionsCode"></el-input>
          </el-form-item> -->
          <el-form-item label="题目:" prop="questionsTitle">
            <el-input type="textarea" :row='2' placeholder="请输入题目" v-model="addForm.questionsTitle"></el-input>
          </el-form-item>
          <el-form-item label="难度系数:" prop="difficulty">
            <el-radio-group v-model="addForm.difficulty">
              <el-radio :label="0">高</el-radio>
              <el-radio :label="1">中</el-radio>
              <el-radio :label="2">低</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题型:" prop="questionsType"> 
            <el-select v-model="addForm.questionsType">
              <el-option label="单选" value="0"></el-option>
              <el-option label="多选" value="1"></el-option>
              <el-option label="判断" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题库:" prop="questionsBank"> 
            <el-select v-model="addForm.questionsBank">
              <el-option label="A库" value="1"></el-option>
              <el-option label="B库" value="2"></el-option>
              <el-option label="C库" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-row justify="center" align="middle" class="checked">
            <template v-if="addForm.questionsType !== '2'">
              <el-col :span="12"><p>选项文字</p></el-col>
              <el-col :span="4"><p>答案</p></el-col>
              <el-col :span="8"><p>操作</p></el-col>
            </template>
          </el-row>
          <!-- 单选 -->
          <template v-if='addForm.questionsType == 0'>
              <el-row v-for="(item,index) in addForm.options" :key="item.answer">
            <el-col :span="12">
              <el-form-item size="medium" label-width="20px" prop="answerContent">
                <el-input v-model="item.answerContent">
                  <template slot="prepend">{{item.selectName}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item size="medium" label-width="50%" prop="answers">
                <el-radio v-model="optionRadio" :label="item.answer">{{item.answers}}</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="operation">
              <el-row>
                <el-col :span="6">
                  <el-button type="primary" size="mini" circle @click.native.prevent="add"><i class="el-icon-plus"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" size="mini" circle @click.native.prevent="sub(index)"><i class="el-icon-minus"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" size="mini" circle @click.native.prevent="up(index)"><i class="el-icon-arrow-up"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" size="mini" circle @click.native.prevent="down(index)"><i class="el-icon-arrow-down"></i></el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          </template>
          <!-- 多选 -->
          <template v-if='addForm.questionsType == 1'>
            <el-row v-for="(item,index) in addForm.options" :key="item.id">
              <el-col :span="12">
                <el-form-item size="medium" label-width="20px" prop="answerContent">
                  <el-input v-model="item.answerContent">
                    <template slot="prepend">{{item.selectName}}</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item size="medium" label-width="50%" prop="answers">
                  <el-checkbox-group v-model='optionCheckbox'>
                    <el-checkbox :label="item.answer">{{item.answer11}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="operation">
                <el-row>
                  <el-col :span="6">
                    <el-button type="primary" size="mini" circle @click.native.prevent="add"><i class="el-icon-plus"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" size="mini" circle @click.native.prevent="sub(index)"><i class="el-icon-minus"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" size="mini" circle @click.native.prevent="up(index)"><i class="el-icon-arrow-up"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" size="mini" circle @click.native.prevent="down(index)"><i class="el-icon-arrow-down"></i></el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <!-- 判断 -->
          <template v-if='addForm.questionsType == 2'>
            <el-row class="judge">
              <el-col :span="4" >
                  <el-form-item label="答案" prop="answers" >
                    <el-radio-group v-model="optionJudge" class="answer">
                      <el-radio :label="true">正确</el-radio>
                      <el-radio :label="false">错误</el-radio>
                    </el-radio-group>
                  </el-form-item>
              </el-col>
            </el-row>
          </template>
          <!-- <el-form-item label="解析:" prop="analysis">
            <el-input type="textarea" v-model="addForm.analysis" placeholder="输入本题解析"></el-input>
          </el-form-item> -->
          <el-form-item label="状态:" prop="isEnable">
            <el-radio-group v-model="addForm.isEnable">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionVisible = false">取 消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="editQuestionVisible"
      :close-on-click-modal="false"
      width="50%"
      >
      <el-form :model="editForm" label-width="130px" label-position="center" ref="editFormRef" :rules="editFormRules">
          <!-- <el-form-item label="编号" prop="questionsCode">
            <el-input :disabled="true" v-model="editForm.questionsCode"></el-input>
          </el-form-item> -->
          <el-form-item label="题目" prop="questionsTitle">
            <el-input :disabled="isDisabled" type="textarea" :row='2' placeholder="请输入题目" v-model="editForm.questionsTitle"></el-input>
          </el-form-item>
          <el-form-item label="难度系数" prop="difficulty">
            <el-radio-group :disabled="isDisabled" v-model="editForm.difficulty">
              <el-radio :label="0">高</el-radio>
              <el-radio :label="1">中</el-radio>
              <el-radio :label="2">低</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题型" prop="questionsType"> 
            <el-select :disabled="isDisabled" v-model="editForm.questionsType">
              <el-option  label="单选" :value="0"></el-option>
              <el-option  label="多选" :value="1"></el-option>
              <el-option  label="判断" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题库:" prop="questionsBank"> 
            <el-select v-model="editForm.questionsBank">
              <el-option label="A库" value="1"></el-option>
              <el-option label="B库" value="2"></el-option>
              <el-option label="C库" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-row justify="center" align="middle" class="checked">
            <template v-if="editForm.questionsType !== 2">
              <el-col :span="12"><p>选项文字</p></el-col>
              <el-col :span="4"><p>答案</p></el-col>
              <el-col :span="8"><p>操作</p></el-col>
            </template>
          </el-row>
          <!-- 单选 -->
          <template v-if='editForm.questionsType == 0'>
            <el-row v-for="(item,index) in editForm.options" :key="item.id">
            <el-col :span="12">
              <el-form-item size="medium" label-width="80px" prop="answerContent">
                <el-input :disabled="isDisabled" v-model="item.AnswerContent">
                  <template slot="prepend">{{item.selectName}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item size="medium" label-width="50%" prop="answers">
                <el-radio :disabled="isDisabled" v-model="optionRadio" :label="item.answer">{{item.answercc}}</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="operation">
              <el-row>
                <el-col :span="6">
                  <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="addEdit"><i class="el-icon-plus"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="subEdit(index)"><i class="el-icon-minus"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="upEdit(index)"><i class="el-icon-arrow-up"></i></el-button>
                </el-col>
                <el-col :span="6">
                  <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="downEdit(index)"><i class="el-icon-arrow-down"></i></el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          </template>
          <!-- 多选 -->
          <template v-if='editForm.questionsType == 1'>
            <el-row v-for="(item,index) in editForm.options" :key="item.id">
              <el-col :span="12">
                <el-form-item size="medium" label-width="80px" prop="answerContent">
                  <el-input :disabled="isDisabled" v-model="item.AnswerContent">
                    <template slot="prepend">{{item.selectName}}</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item size="medium" label-width="50%" prop="answers">
                  <el-checkbox-group :disabled="isDisabled" v-model='optionCheckbox'>
                    <el-checkbox :label="index">{{item.answer11}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="operation">
                <el-row>
                  <el-col :span="6">
                    <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="addEdit"><i class="el-icon-plus"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="subEdit(index)"><i class="el-icon-minus"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="upEdit(index)"><i class="el-icon-arrow-up"></i></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :disabled="isDisabled" type="primary" size="mini" circle @click.native.prevent="downEdit(index)"><i class="el-icon-arrow-down"></i></el-button>
                  </el-col>
                </el-row>
              </el-col>
          </el-row>
          </template>
          <!-- 判断 -->
          <template v-if='editForm.questionsType == 2'>
            <el-row class="judgeAnswer">
              <el-col :span="4" >
                <el-form-item label="答案" prop="answers" class="answerBox">
                  <el-radio-group :disabled="isDisabled" v-model="optionJudge" class="answer">
                    <el-radio :label="true">正确</el-radio>
                    <el-radio :label="false">错误</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <!-- <el-form-item label="解析" prop="analysis">
            <el-input :disabled="isDisabled" type="textarea" v-model="editForm.analysis" placeholder="输入本题解析"></el-input>
          </el-form-item> -->
          <el-form-item label="状态" prop="isEnable">
            <el-radio-group :disabled="isDisabled" v-model="editForm.isEnable">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
      </el-form>
      <el-divider v-if="isDictionary > 0"></el-divider>
      <el-form v-if="isDictionary > 0" :model="approveForm" label-width="130px" label-position="center" ref="approveFormRef" :rules="approveFormRules">
        <el-form-item label="审批记录名称：" prop="recordName">
          <el-input v-model="approveForm.recordName"></el-input>
        </el-form-item>
        <el-form-item label="审批流程：" prop="approvalProcessID"> 
          <el-select v-model="approveForm.approvalProcessID">
            <el-option v-for="item in dataPer" :key="item.index" :label="item.ProcessName" :value="item.ID"></el-option>
            <!-- <el-option  label="审批2" :value="1"></el-option>
            <el-option  label="审批3" :value="2"></el-option> -->
            <el-pagination
            :hide-on-single-page="perValue"
            :total="perPages"
            layout="prev, pager, next"
            @current-change="changePage">
          </el-pagination>
          </el-select>
        </el-form-item>
        <el-form-item label="说明：">
          <el-input v-model="approveForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editQuestionVisible = false">取 消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="addLoading1">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="importQuestionsDialog"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-upload
        drag
        :limit='1'
        ref="upload"
        :on-error="submitFileError"
        :on-success="submitFileSuccess"
        :before-upload="beforeUploadFile"
        :on-exceed="submitFileMore"
        :show-file-list="false"
        :action="actions"
        :headers='headers'
        :multiple='false'
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传文件，且不超过2MB</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importQuestionsDialog = false">取 消</el-button>
        <!-- <el-button type="primary" @click.native="importQuestionsSubmit" :loading="importQuestionsLoading">确定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import {getExaminationQuestionsListPage,deleteExaminationQuestions,addExaminationQuestions,updateExaminationQuestions,shareOrPrivateExaminationQuestions,getApprovalProcessListPage,convertDictionaryExaminationQuestionsApproval,changeDictionaryExaminationQuestionsApproval,importExaminationQuestions } from '@/api/api'
export default {
  components:{Toolbar},
  data(){
    return {
      isAlive:true, // 控制列表能被及时渲染
      // 筛选
      filters:{
        questionType:'',
        questionGrade:'',
        questionRange:'',
        questionCode:'',
        questionSearch:''
      },
      // 增删改查等按钮
      buttonList: [],
      questionType:'',
      // 选择的题型
      TypeOption:[
        // {
        //   value:'',
        //   label:'所有题型'
        // },
        {
          value:'0',
          label:'单选题'
        },
        {
          value:'1',
          label:'多选题'
        },
        {
          value:'2',
          label:'判断题'
        },        
      ],
      questionGrade:'',
      // 选择的难度等级
      GradeOption:[
        // {
        //   value:'',
        //   label:'所有难度'
        // },
        {
          value:'0',
          label:'高'
        },
        {
          value:'1',
          label:'中'
        },
        {
          value:'2',
          label:'低'
        },
      ],
      questionRange:'',
      //选择的范围
      RangeOption:[
        // {
        //   value:'',
        //   label:'所有试题'
        // },
        {
          value:'0',
          label:'字典试题'
        },
        {
          value:'1',
          label:'公开试题'
        },
        {
          value:'2',
          label:'私有试题'
        },
      ],
      //题库
      questionCodeArr:[
        // {
        //   value:'',
        //   label:'所有试题'
        // },
        {
          value: 1,
          label: 'A库'
        },
        {
          value: 2,
          label: 'B库'
        },
        {
          value: 3,
          label: 'C库'
        },
      ],
      // 表格中的数据
      tableData:[],
      addLoading:false,
      addLoading1:false,
      // 分页
      pages:{
        pageArr:[10,20,30,40],
        pageSize:20,
        pageIndex:1,
        dataCount:1,
      },
      // 加载控制
      loading:false,
      // 控制新增显示与隐藏
      addQuestionVisible:false,
      // 控制编辑显示与隐藏
      editQuestionVisible:false,
      // 新增数据
      addForm:{
        questionsCode:'',
        questionsTitle:'',
        difficulty:0,
        questionsType:'',
        questionsBank:'',
        analysis:'',//试题解析
        isEnable:true,
        options:[
          {
            id:0,
            selectName: 'A',
            examinationQuestionsID:0,
            answerContent:'',
            isAnswer:false,
            answer:1
          },
          {
            id:1,
            selectName: 'B',
            examinationQuestionsID:1,
            answerContent:'',
            isAnswer:false,
            answer:2
          }        
        ]
      },
      // 编辑数据
      editForm:{
        questionsCode:'',
        questionsTitle:'',
        difficulty:0,
        questionsType:'',
        questionsBank:'',
        analysis:'',//试题解析
        isEnable:true,
        // ID:null,
        options:[
          {
            id:0,
            selectName: 'A',
            examinationQuestionsID:0,
            answerContent:'',
            isAnswer:false,
            answer:1
          },
          {
            id:1,
            selectName: 'B',
            examinationQuestionsID:1,
            answerContent:'',
            isAnswer:false,
            answer:2
          }        
        ]
      },
      addFormRules:{
        questionsTitle:[{required:true,message:'请输入题目',trigger:'blur'}],
        difficulty:[{required:true,message:'请选择难度',trigger:'change'}],
        questionsType:[{required:true,message:'请选择类型',trigger:'change'}],
        questionsBank:[{required:true,message:'请选择题库',trigger:'change'}],
        isEnable:[{required:true,message:'请选择状态',trigger:'change'}],
      },
      editFormRules:{
        questionsCode:[
          {required:true,message:'请输入数字格式的编号',trigger:'blur'},
        ],
        questionsTitle:[{required:true,message:'请输入题目',trigger:'blur'}],
        difficulty:[{required:true,message:'请选择难度',trigger:'change'}],
        questionsType:[{required:true,message:'请选择类型',trigger:'change'}],
        questionsBank:[{required:true,message:'请选择题库',trigger:'change'}],
        isEnable:[{required:true,message:'请选择状态',trigger:'change'}],
      },
      currentRow:null,  //当前行
      allSelect:[],//所有行
      // 单选
      optionRadio: '',
      // 多选
      optionCheckbox:[],
      // 判断
      optionJudge:'',
      dialogTitle: '编辑题目',
      // 字典试题变更数据
      isDictionary: 0,
      approveForm: {
        recordName: '',
        approvalProcessID: '',
        remarks: ''
      },
      approveFormRules:{
        recordName:[{required:true,message:'请输入编号',trigger:'blur'}],
        approvalProcessID:[{required:true,message:'请输入题目',trigger:'blur'}],
      },
      dataPer:[],
      perValue: false,
      perPages:0,
      perPagesIndex:1,
      isDisabled: false,
      isChange: 0,
      processType: '',
      // 导入
      importQuestionsDialog: false,
      actions:'',
    }
  },
  methods:{
    reload(){
      this.isAlive = false
      this.$nextTick(() => this.isAlive = true)
    },
    // 转为字典试题审批
    handleConvertDicEQ() {
      let row = this.currentRow
      if(!row){
        this.$message({
          message:'请选择要转为试题！',
          type:'warning'
        })
        return
      }
      if(row.UseRange == 0){
        this.$message({
          message:'该试题已是字典试题！',
          type:'warning'
        })
        return
      }
      this.approveForm = {
        recordName: '',
        approvalProcessID: '',
        remarks: ''
      },
      this.isDictionary = 1
      this.isChange = 1
      this.isDisabled = true;
      this.processType = 0
      this.dialogTitle = '转为字典试题'
      this.callbackViewsData(row);
      this.getApprovalList();
      this.editQuestionVisible = true;
    },
    // 变更字典试题审批
    handleChangeDicEQ() {
      let row = this.currentRow
      if(!row){
        this.$message({
          message:'请选择要变更试题！',
          type:'warning'
        })
        return
      }
      if(row.UseRange !== 0){
        this.$message({
          message:'该试题不可变更！',
          type:'warning'
        })
        return
      }
      this.isDictionary = 1
      this.isChange = 2
      this.isDisabled = false;
      this.processType = 1
      this.dialogTitle = '变更字典试题'
      this.callbackViewsData(row);
      this.getApprovalList();
      this.editQuestionVisible = true;
    },
    // 批量试题导入
    importExamiQuestions() {
      this.importQuestionsDialog = true
      
    },
    // 试题上传限制要求
    beforeUploadFile(file) {
      const isLt2M = file.size / 1024 / 1024 > 2;
      var type = file.name.substring(file.name.lastIndexOf(".")+1);
      if (type == 'jpg' || type == 'png') {
        this.$message.error('只能上传文件!');
        return false;
      }
      if (!isLt2M === false) {
        this.$message.error('上传文件大小不能超过 2MB!');
        return false;
      }
    },
    submitFileSuccess(res,file,fileList) {
      if(res.Success){
          this.$message({
            message:'文件上传成功！',
            type:'success'
          })
          this.getQuestion();
          this.importQuestionsDialog = false
          this.$refs.upload.clearFiles() 
      }else{
        this.$message({
          message:res.Message,
          type:'error'
        })
        this.$refs.upload.clearFiles() 
      }
    },
    submitFileError(err,file,fileList){
      this.$message.error('文件上传失败!')
      this.$refs.upload.clearFiles() 
    },
    submitFileMore(files) {
      this.$message({
        message:'只能上传一个文件！',
        type:'warning'
      })
    },
    // 获取所有题目数据
    getQuestion(){
      this.loading = true
      var params = {
          pageSize:this.pages.pageSize,
          pageIndex:this.pages.pageIndex,
          questionsType:this.filters.questionType?this.filters.questionType:'',
          difficulty:this.filters.questionGrade?this.filters.questionGrade:'',
          // useRange:this.filters.questionRange?this.filters.questionRange:'',
          questionCode:this.filters.questionCode?this.filters.questionCode:'',
          questionsTitle:this.filters.questionSearch?this.filters.questionSearch:''
      }
      getExaminationQuestionsListPage(params).then(res => {     
        this.tableData = res.data.Response.Data
        this.pages.dataCount = res.data.Response.DataCount
        this.loading = false
      })
    },
    // 数据条数改变
    handleSizeChange(newSize){
      this.pages.pageSize = newSize   
      this.getQuestion()
    },
    // 当前页改变
    handleCurrentChange(newPage){
      // if(this.filters.questionGrade != '' || this.filters.questionRange != '' || this.filters.questionSearch != '' || this.filters.questionType != ''){
      //   this.pages.pageIndex = 1
      // }else{
        this.pages.pageIndex = newPage
      // }         
      this.getQuestion()
    },
    // 表格当前行改变事件
    handlerowChange(currentRow){
      this.currentRow = currentRow
	    console.log(currentRow,'currentrow')
    },
    // 选中表格行
    handleSelectChange(allRow){
      this.allSelect = allRow
    },
    // 按钮
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    // 查询
    getExaminationQuestionsListPage() {  
      this.pages.pageIndex = 1
      this.getQuestion()
    },
    // 新增
    handleAdd(){
      this.addQuestionVisible = true
      this.addForm = {
        questionsCode:'',
        questionsTitle:'',
        difficulty:0,
        questionsType:'',
        questionsBank:'',
        analysis:'',//试题解析
        isEnable:true,
        options:[
          {
            id:0,
            selectName: 'A',
            examinationQuestionsID:0,
            answerContent:'',
            isAnswer:false,
            answer:1
          },
          {
            id:1,
            selectName: 'B',
            examinationQuestionsID:1,
            answerContent:'',
            isAnswer:false,
            answer:2
          }        
        ]
      }
      this.optionRadio = ''
      this.optionCheckbox = []
      this.optionJudge = ''
    },
    // 新增提交表单
    addSubmit(){
      this.$refs['addFormRef'].validate((valid) => {
        if(valid){
          if(!this.optionRadio && this.addForm.questionsType == "0"){
            this.$message({
              message: '未选择试题答案！',
              type: 'warning'
            });
            return
          }
          if(this.optionCheckbox.length>0 && this.addForm.questionsType == 1){
            for(var i = 0; i < this.addForm.options.length; i++){
              if(this.addForm.options[i].answerContent){}else{
                this.$message({
                  message: '请输入选项内容！',
                  type: 'warning'
                });
                return
              }
            }
          }else if(this.addForm.questionsType == 0 || this.addForm.questionsType == 2){
            if(this.addForm.questionsType == 0){
              for(var i = 0; i < this.addForm.options.length; i++){
                if(this.addForm.options[i].answerContent){}else{
                  this.$message({
                    message: '请输入选项内容！',
                    type: 'warning'
                  });
                  return
                }
              }
            }else{
              if(this.optionJudge === true){
              }else if(this.optionJudge === false){
              }else{
                this.$message({
                  message: '未选择试题答案！',
                  type: 'warning'
                });
                return
              }
            }
          }else{
            this.$message({
              message: '未选择试题答案！',
              type: 'warning'
            });
            return
          }
          this.$confirm('确认提交吗？',"提示",{
          }).then(() => {
            var params = {}
            if(this.addForm.questionsType !== "2"){
              if(this.addForm.questionsType === "0"){
                var optionsArr = []
                this.addForm.options.forEach((item) => {
                  var dataObj = {}
                  dataObj.AnswerContent = item.answerContent
                  dataObj.IsAnswer = false
                  optionsArr.push(dataObj)
                })
                optionsArr[this.optionRadio-1].isAnswer = true
              }else if(this.addForm.questionsType === "1"){
                var optionsArr = []
                this.addForm.options.forEach((item) => {
                  var dataObj = {}
                  dataObj.AnswerContent = item.answerContent
                  dataObj.IsAnswer = false
                  optionsArr.push(dataObj)
                })
                for(var j = 0; j < this.optionCheckbox.length;j++){
                  optionsArr[this.optionCheckbox[j]-1].IsAnswer = true
                }
              }
              params = {
                QuestionsCode:this.addForm.questionsCode ? this.addForm.questionsCode : '',  ////////
                QuestionsTitle:this.addForm.questionsTitle,
                Difficulty:this.addForm.difficulty,
                QuestionsType:this.addForm.questionsType,
                QuestionCode:this.addForm.questionsBank,
                Analysis:this.addForm.analysis,
                IsEnable:this.addForm.isEnable,
                Options:optionsArr               
              }
            }else{
              var optionsArr = [{IsAnswer:false},{IsAnswer:true}]
              if(this.optionJudge){
                optionsArr[0].IsAnswer = true
                optionsArr[0].AnswerContent = '正确'
                optionsArr[1].IsAnswer = false
                optionsArr[1].AnswerContent = '错误'
              }else{
                optionsArr[0].IsAnswer = false
                optionsArr[0].AnswerContent = '正确'
                optionsArr[1].IsAnswer = true
                optionsArr[1].AnswerContent = '错误'
              }
              params = {
                QuestionsCode:this.addForm.questionsCode ? this.addForm.questionsCode : '',
                QuestionsTitle:this.addForm.questionsTitle,
                Difficulty:this.addForm.difficulty,
                QuestionsType:this.addForm.questionsType,
                QuestionCode:this.addForm.questionsBank,
                Analysis:this.addForm.analysis,
                IsEnable:this.addForm.isEnable,
                Options:optionsArr               
              }
            }
            this.addLoading = true
            addExaminationQuestions(params).then(res => {
              if(res.data.Success) {
                this.loading = false;
                this.$message({
                    message: '新增成功',
                    type: 'success'
                });
                  this.addQuestionVisible = false;
                  this.addLoading = false;
                  this.$refs['addFormRef'].resetFields();
                  this.getQuestion();
              }else{
                this.loading = false;
                this.$message({
                  message:'新增失败',
                  type:'error'
                })
                this.addLoading = false;
              }
            })
          })
        }
      })
    },
    // 编辑提交表单
    editSubmit(){
      if(this.isDictionary > 0){
        this.$refs['approveFormRef'].validate((valid) => {
          if(valid){
            this.$refs['editFormRef'].validate((valid) => {
              if(valid){
                this.$confirm('确认提交吗？',"提示",{
                }).then(() => {
                  this.isSubmitEdit();
                })
              }
            })
          }else{
            return
          }
        })
      }else{
        if(this.optionCheckbox.length > 0 && this.editForm.questionsType == 1){
          this.$refs['editFormRef'].validate((valid) => {
            if(valid){
              for(var i = 0; i < this.editForm.options.length; i++){
                if(this.editForm.options[i].AnswerContent){}else{
                  this.$message({
                    message: '请输入选项内容！',
                    type: 'warning'
                  });
                  return
                }
              }
              this.$confirm('确认提交吗？',"提示",{
              }).then(() => {
                this.isSubmitEdit();
              })
            }
          })
        }else if(this.editForm.questionsType == 0 || this.editForm.questionsType == 2){
          this.$refs['editFormRef'].validate((valid) => {
            if(valid){
              if(this.editForm.questionsType == 0){
                for(var i = 0; i < this.editForm.options.length; i++){
                  if(this.editForm.options[i].AnswerContent){}else{
                    this.$message({
                      message: '请输入选项内容！',
                      type: 'warning'
                    });
                    return
                  }
                }
              }else{
                if(this.optionJudge === true){
                }else if(this.optionJudge === false){
                }else{
                  this.$message({
                    message: '未选择试题答案！',
                    type: 'warning'
                  });
                  return
                }
              }
              this.$confirm('确认提交吗？',"提示",{
              }).then(() => {
                this.isSubmitEdit();
              })
            }
          })
        }else{
          this.$message({
            message: '未选择试题答案！',
            type: 'warning'
          });
          return
        }
      }
    },
    isSubmitEdit() {
      var params = {}
      var dataObj = {}
      if(this.editForm.questionsType !== 2){
        if(this.editForm.questionsType === 0){
          var optionsArr = []
          this.editForm.options.forEach((item) => {
            var dataObj = {}
            dataObj.AnswerContent = item.AnswerContent
            dataObj.IsAnswer = false
            optionsArr.push(dataObj)
          })
          optionsArr[this.optionRadio-1].isAnswer = true
        }else if(this.editForm.questionsType === 1){
          var optionsArr = []
          this.editForm.options.forEach((item) => {
            var dataObj = {}
            dataObj.AnswerContent = item.AnswerContent
            dataObj.IsAnswer = false
            optionsArr.push(dataObj)
          })
          for(var j = 0; j < this.optionCheckbox.length;j++){
            optionsArr[this.optionCheckbox[j]].IsAnswer = true
          }
        }
        params = {
          QuestionsCode:this.editForm.questionsCode,
          QuestionsTitle:this.editForm.questionsTitle,
          Difficulty:this.editForm.difficulty,
          QuestionsType:this.editForm.questionsType,
          QuestionCode:this.editForm.questionsBank,
          Analysis:this.editForm.analysis,
          IsEnable:this.editForm.isEnable,
          Options:optionsArr,
          ID:this.editForm.id             
        }
      }else{
        var optionsArr = [{IsAnswer:false},{IsAnswer:true}]
        if(this.optionJudge){
          optionsArr[0].IsAnswer = true
          optionsArr[0].AnswerContent = '正确'
          optionsArr[1].IsAnswer = false
          optionsArr[1].AnswerContent = '错误'
        }else{
          optionsArr[0].IsAnswer = false
          optionsArr[0].AnswerContent = '正确'
          optionsArr[1].IsAnswer = true
          optionsArr[1].AnswerContent = '错误'
        }
        params = {
          QuestionsCode:this.editForm.questionsCode,
          QuestionsTitle:this.editForm.questionsTitle,
          Difficulty:this.editForm.difficulty,
          QuestionsType:this.editForm.questionsType,
          QuestionCode:this.editForm.questionsBank,
          Analysis:this.editForm.analysis,
          IsEnable:this.editForm.isEnable,
          Options:optionsArr,
          ID:this.editForm.id,
        }
      }
      if(this.isDictionary > 0){
        params.ApprovalRecord = this.approveForm
        params.ExaminationQuestionsID = this.editForm.id
        if(this.isChange == 1){
          this.updataConverExam(params)
        }else if(this.isChange == 2){
          this.updataChange(params)
        }
      }else{
        this.updataExamPro(params)
      }
    },
    updataExamPro(params) {
      this.addLoading1 = true
      updateExaminationQuestions(params).then(res => {
        if (res.data.Success) {
          this.loading = false;
          this.$message({
              message: '编辑成功',
              type: 'success'
          });
          this.editQuestionVisible = false;
          this.addLoading1 = false;
          this.$refs['editFormRef'].resetFields();
          this.getQuestion();
        }
        else{
          this.loading = false;
          this.addLoading1 = false;
          this.$message({
            message:res.data.Message,
            type:'error'
          })
        }
      })
    },
    // 转为字典试题
    updataConverExam(params) {
      this.addLoading1 = true
      convertDictionaryExaminationQuestionsApproval(params).then(res => {
        if (res.data.Success) {
          this.loading = false;
          this.$message({
              message: '转为成功！',
              type: 'success'
          });
          this.editQuestionVisible = false;
          this.addLoading1 = false;
          this.$refs['editFormRef'].resetFields();
          this.getQuestion();
        }
        else{
          this.loading = false;
          this.addLoading1 = false;
          this.$message({
            message:res.data.Message,
            type:'error'
          })
        }
      })
    },
    // 变更字典试题
    updataChange(params) {
      this.addLoading1 = true
      changeDictionaryExaminationQuestionsApproval(params).then(res => {
        if (res.data.Success) {
          this.loading = false;
          this.$message({
              message: '转为成功！',
              type: 'success'
          });
          this.editQuestionVisible = false;
          this.addLoading1 = false;
          this.$refs['editFormRef'].resetFields();
          this.getQuestion();
        }
        else{
          this.loading = false;
          this.addLoading1 = false;
          this.$message({
            message:res.data.Message,
            type:'error'
          })
        }
      })
    },
    // 
    // 添加选项
    add(){
      var letter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      var obj = {
        id:this.addForm.options.length+1,
        selectName: letter[this.addForm.options.length],
        examinationQuestionsID:0,
        answerContent:'',
        isAnswer:false,
        answer:this.addForm.options.length+1
      }
      this.addForm.options.push(obj)
    },
    // 减少选项
    sub(index){
      if(this.addForm.options.length <= 2){
        this.$message({
          type:'warning',
          message:'选项不得少于2个,不能再删了'
        })
        return
      }
      this.addForm.options.splice(index,1)
      var letter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(var i = 0; i < this.addForm.options.length; i++){
        this.addForm.options[i].id = i
        this.addForm.options[i].selectName = letter[i]
      }
    },
    // 选项上移
    up(index){
      if(index !== 0){
        var value1 = this.addForm.options[index].answerContent
        var value2 = this.addForm.options[index-1].answerContent
        this.addForm.options[index-1].answerContent  = value1 
        this.addForm.options[index].answerContent = value2
      }else{
        this.$message({
          showClose:true,
          message:'不能再上移了！',
          type:'warning'
        })
      }
    },          
    // 选项下移
    down(index){
      if(index !== this.addForm.options.length-1){
        var value1 = this.addForm.options[index].answerContent
        var value2 = this.addForm.options[index+1].answerContent
        this.addForm.options[index+1].answerContent  = value1 
        this.addForm.options[index].answerContent = value2
      }else{
        this.$message({
          showClose:true,
          message:'不能再下移了！',
          type:'warning'
        })
      }
      
    },
    // 添加选项(编辑)
    addEdit(){
      var letter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      var obj = {
        // id:this.editForm.options.length+1,
        selectName: letter[this.editForm.options.length],
        examinationQuestionsID:0,
        AnswerContent:'',
        isAnswer:false,
        answer:this.editForm.options.length+1
      }
      // for(var j = 0;j<this.editForm.options.length;j++){
      //   obj.selectName = letter[this.editForm.options.length]
      //   obj.id = j + 2
      //   obj.answer = j + 2
      //   obj.AnswerContent = ''
      // }
      this.editForm.options.push(obj)
    },
    // 减少选项(编辑)
    subEdit(index){
      if(this.editForm.options.length <= 2){
        this.$message({
          type:'warning',
          message:'选项不得少于2个,不能再删了'
        })
        return
      }
      this.editForm.options.splice(index,1)
      var letter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(var i = 0; i < this.editForm.options.length; i++){
        this.editForm.options[i].id = i
        this.editForm.options[i].selectName = letter[i]
      }
    },
    // 选项上移(编辑)
    upEdit(index){
      if(index !== 0){
        var value1 = this.editForm.options[index].AnswerContent
        var value2 = this.editForm.options[index-1].AnswerContent
        this.editForm.options[index-1].AnswerContent  = value1 
        this.editForm.options[index].AnswerContent = value2
      }else{
        this.$message({
          showClose:true,
          message:'不能再上移了！',
          type:'warning'
        })
      }
    },          
    // 选项下移(编辑)
    downEdit(index){
      if(index !== this.editForm.options.length-1){
        var value1 = this.editForm.options[index].AnswerContent
        var value2 = this.editForm.options[index+1].AnswerContent
        this.editForm.options[index+1].AnswerContent  = value1 
        this.editForm.options[index].AnswerContent = value2
      }else{
        this.$message({
          showClose:true,
          message:'不能再下移了！',
          type:'warning'
        })
      }
      
    },
    // 编辑
    handleEdit(){
      this.dialogTitle = '编辑题目'
      this.isDisabled = false
      let row = this.currentRow
      if(!row){
        this.$message({
          type:'error',
          message:'请选择要编辑的一行数据！'
        })
        return
      }
      this.isDictionary = 0
      this.callbackViewsData(row);
      this.editQuestionVisible = true;
    },
    // 编辑回显
    callbackViewsData(row) {
      this.editForm = {
        questionsCode:row.QuestionsCode,
        questionsTitle:row.QuestionsTitle,
        difficulty:row.Difficulty,
        questionsType:row.QuestionsType,
        questionsBank:String(row.QuestionCode),
        analysis: row.Analysis,
        isEnable:row.IsEnable,
        id:row.ID,
        options:row.Options
      }
      var letter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      this.optionCheckbox = []
      for(var i = 0; i < row.Options.length; i++){
        var selectName = letter[i]
        row.Options[i].selectName = selectName
        row.Options[i].answer = i+1
        this.editForm.options[i].isAnswer = row.Options[i].IsAnswer
        this.editForm.options[i].answerContent = row.Options[i].AnswerContent
        this.editForm.options[i].id = row.Options[i].ID
        this.editForm.options[i].examinationQuestionsID = row.Options[i].ExaminationQuestionsID
        if(this.editForm.questionsType== 1 && this.editForm.options[i].isAnswer){         
          this.optionCheckbox.push(i)
        }else if(this.editForm.questionsType== 0 && this.editForm.options[i].isAnswer){
          this.optionRadio = i+1
        }else if(this.editForm.questionsType== 2 && this.editForm.options[0].isAnswer){
           this.optionJudge = true
        }else if(this.editForm.questionsType== 2 && !this.editForm.options[1].isAnswer){
          this.optionJudge = false
        }
      }
    },
    // 删除
    handleDel(){
      var row = this.currentRow
      if(row == null){
        this.$message({
          showClose: true,
          message: '请选择要删除的一行数据',
          type: 'error'
        })
        return
      }
      this.$confirm('确定要删除该行数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        var params = {
          Id:row.ID
        }
        deleteExaminationQuestions(params).then(res => {
          if (util.isEmt.format(res)) {
              this.loading = false;
              return;
          }
          if(res.data.Success){
            this.loading = false
            this.$message({
              type:'success',
              message:res.data.Message
            })
            this.addQuestionVisible = false
            this.getQuestion()
          }else{
            this.loading = false
            this.$message({
              type:"error",
              message:res.data.Message
            })
          }            
        })         
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          }); 
        })
    },
    // 公有/私有
    handleShareOrPrivate(){
      var row = this.currentRow
      if(row == null){
        this.$message({
          showClose:true,
          message:'请选择一行数据',
          type:'error'
        })
        return
      }     
      this.$confirm('确定要公开/私有这道题吗？','提示',{
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
      }).then(() => {
        this.loading = true
        shareOrPrivateExaminationQuestions({ID:row.ID}).then(res => {
          if(util.isEmt.format(res)) {
            this.loading = false;
            return;
          }
          if(res.data.Success){
            this.loading = false
            this.$message({
              type:'success',
              message:res.data.Message
            })
            this.getQuestion()
          }else{
            this.loading = false
            this.$message({
              type:'error',
              message:res.data.Message
            })
          }
        })
      }).catch(() => {})
    }, 
    // 获取列表
    getApprovalList() {
      var params = {
        pageIndex: this.perPagesIndex,
        pageSize: 10,
        processType: this.processType
      }
      getApprovalProcessListPage(params).then(res => {
        var resData = res.data
        if(resData.Success){
          this.dataPer = resData.Response.Data
          this.perPages = resData.Response.DataCount
          // }
        }else{
          this.$message({
            message: resData.Message,
            type: "error"
          });
        }
      });
    },
    changePage(val) {
      this.perPagesIndex = val
      this.getApprovalList()
    },
    getToken() {
      return localStorage.getItem("Token")
    },
    // 转为字典试题审批
    // handleConvertDicEQ(){

    // },
    // 时间格式化
    formatCreateTime:function(row,column){
      return !row.CreateTime || row.CreateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
    },       
  },
  computed: {
    headers() {
      return{
        "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
      }
    }
  },
  created(){
	  // this.actions = importExaminationQuestions
	  // const base = process.env.BASE_URL
	  const base = ''
	  this.actions = `${base}/sstwebapi/examinationQuestions/importExaminationQuestions` + `/` + parseInt(window.localStorage.getItem('projectId'))
    this.getQuestion()
  },
	watch:{
		// 'projectId':function(newVal,oldVal){
		// 	window.location.reload();
		// 	this.actions = importExaminationQuestions
    // }
	},
  mounted(){
    let routers = window.localStorage.router
    ? JSON.parse(window.localStorage.router)
    : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  }

}
</script>

<style lang="stylus" scoped>
  .pageBarContainer{
    margin-top:20px;
		margin-bottom :20px
  }
  .special{
    display:flex!important;
    justify-content:center;
  }
  .checked .el-col p{
    display:inline-blocck!important;
    text-align:center!important;
  }
  .operation .el-col .el-button{
    display:block!important;
    margin:0 auto!important;
  }
  .judgeAnswer{
    vertical-align:middle!iportant;
    line-height:84px;
  }
  .answer{
    display:flex!important;
    align-items :center;
    height:45px;
  }
</style>